import React, {useState, useEffect} from "react"
import parse from 'html-react-parser';
import {Link} from 'gatsby';
import styled from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_block_statement.scss";

const ContainsCustomColor = styled.div`
width:100%;
display: flex;

  section{
    color:${(props) => props.textColor};
    background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2});
  }
`

const BasicBlockStatement  = (data) => {
  let classList = [
    'block-statement',
    'section'
  ]; 
  
  if(data.removeBottomMargin === true){
    classList.push('no-margin');
  }

  const [isInview, setIsInview] = useState(false);
  const [color1, setColor1] = useState(null);
  const [color2, setColor2] = useState(null);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  useEffect(() => {
    if(typeof document !== 'undefined'){
      setColor1(document.querySelector('.outer-component-wrapper').getAttribute('data-color1'))
      setColor2(document.querySelector('.outer-component-wrapper').getAttribute('data-color2'))
    }
  }, [])

  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <ContainsCustomColor
        customColour1={color1}
        customColour2={color2}
        textColor={data.textColour}
        >
          <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`} data-bg-shape="show_shape">
            <div className="wrapper clearfix">
            {parse(data.statement)}
            </div>
          </section>
        </ContainsCustomColor>
      }
    </VisibilitySensor>
  )
}

export default BasicBlockStatement 
