import React, {useState, useEffect, useRef} from "react"
import parse from 'html-react-parser';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_text-image.scss";

const ContainsCustomColours = styled.div`

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    background:var(--black);
    background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const VideoWrapper = styled.div`
background:inherit;
cursor: pointer;
background:-webkit-linear-gradient(top, ${(props) => props.backgroundColour1}, ${(props) => props.backgroundColour2})  !important;

`
const ServiceTextVideoBlock = (data) => {
  
  let removeBottomMargin = data.removeBottomMargin;
  let backgroundColour1 = data.backgroundColour1;
  let backgroundColour2 = data.backgroundColour2;
  let video = data.video;
  let imageFocalPoint = data.imageFocalPoint;
  let imageToFillSpace = data.imageToFillSpace;
  let text = parse(data.text);
  const videoRef = useRef()
  
  let classList = [
    'text-image', 
    'video', 
    'contains-custom-colour',
    'section'
  ]; 
  
  if(removeBottomMargin === true){
    classList.push('no-margin');
  }
  if(imageFocalPoint === true){
    classList.push(imageFocalPoint);
  }

  
  const [isInview, setIsInview] = useState(false);

  function restartVideo(){
    videoRef.current.currentTime = 0;
    videoRef.current.play()
  }

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  function videoIsInview (isVisible) {
    restartVideo()
  }

  const [customColour1, setCustomColour1] = useState();
  const [customColour2, setCustomColour2] = useState();
  
  useEffect(() => {
    if(typeof document !== 'undefined'){
        setCustomColour1(document.querySelector('.outer-component-wrapper').getAttribute('data-color1'))
        setCustomColour2(document.querySelector('.outer-component-wrapper').getAttribute('data-color2'))
    }
  }, [])

  

  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`} data-bg-shape="show_shape">
          <ContainsCustomColours customColour1={customColour1} customColour2={customColour2}  className="wrapper">
            <div className="text-area no-margin">{text}</div>
            <VisibilitySensor partialVisibility={true} offset={{bottom:120}} onChange={videoIsInview}>
              {({isVisible}) =>
                <VideoWrapper onClick={restartVideo} backgroundColour1={backgroundColour1} backgroundColour2={backgroundColour2} className={imageToFillSpace === true ? 'image cover' : 'image contain'}>
                  <video ref={videoRef} playsInline muted autoPlay className={imageToFillSpace === true ? 'cover' : 'contain'}>
                    <source src={video} type="video/mp4"></source>
                  </video>
                </VideoWrapper>
               }
            </VisibilitySensor>
          </ContainsCustomColours>
        </section>
      }
    </VisibilitySensor>
  )
}

export default ServiceTextVideoBlock
